import React from "react"
import {Helmet} from "react-helmet";
import TwanbLink from "../components/twanb-link"

import appletouch from '../img/apple-touch-icon.png'
import favicon from '../img/favicon.png'
import favicon32 from '../img/favicon-32x32.png'
import favicon16 from '../img/favicon-16x16.png'
import maskIcon from '../img/safari-pinned-tab.svg'

import Layout from "../components/layout"
import PageBanner from '../components/PageBanner'

const sanityClient = require('@sanity/client')
const client = sanityClient({
  projectId: 'a8s3bknf',
  dataset: 'production',
  token: process.env.MY_SANITY_TOKEN, // or leave blank to be anonymous user
  useCdn: false // `false` if you want to ensure fresh data
})

class IndexPage extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      postList: []
    }
  }

  componentDidMount() {
  
    const query = `*[_type == "post"] | order(publishedAt desc) {title, slug, excerpt, employee->, _createdAt, publishedAt}`
  
    client.fetch(query).then(posts => {
      posts.forEach(post => {
        this.setState({ postList: [...this.state.postList, post] });
      })
    })

    
  }

  render() {
    return(

      <Layout>
        <div>

          <Helmet
                    title="Not Bungie | TWaNB"
                    meta={[
                        { name: 'description', content: 'What would happen if Cayde spilled ramen on Rasputin\'s records of the Golden Age? Probably something like this.' },
                        { name: 'msapplication-TileColor', content: '#ffc40d' },
                        { name: 'theme-color', content: '#ffffff' },
                        { charset: 'utf-8' },
                        { name: 'viewport', content: 'width=device-width, initial-scale=1, shrink-to-fit=no'},
                        { content: 'Chris Landtiser', name: 'author' },
                        { content: 'en_US', property: 'og:locale'},
                        { content: 'website', property: 'og:type'},
                        { content: 'Not Bungie', property: 'og:title'},
                        { content: 'What would happen if Cayde spilled ramen on Rasputin\'s records of the Golden Age? Probably something like this.', property: 'og:description'},
                        { content: 'https://notbungie.net', property: 'og:url'},
                        { content: 'Not Bungie', property: 'og:site_name'},
                        { content: 'og:image', property: `${favicon}`},
                        { content: '@landtiser', property: 'twitter:creator'},
                    ]}
                    link={[
                        { rel: 'apple-touch-icon', sizes: '180x180', href: `${appletouch}` },
                        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon32}` },
                        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon16}` },
                        { rel: 'manifest', href: `/site.webmanifest` },
                        { rel: 'mask-icon', color: '#5bbad5', href: `${maskIcon}` },
                    ]}
                />

          <PageBanner />
          <div>
            {this.state.postList.length >= 1 &&
              this.state.postList.map(
                post =>
                <TwanbLink key={post.slug.current} post={post}></TwanbLink>
              )
            }
          </div>
        </div>
      </Layout>

    )}
}

export default IndexPage